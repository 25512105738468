import { Auth } from '@leagueplatform/auth';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { captureAppMessage } from '@leagueplatform/observability';
import { fetchPartnerIdToken } from 'api/get-partner-id-token';
import { SENTRY_CONSTANTS } from 'common/constants';

/**
 * Logs the user out using federated authentication.
 * @param returnUrl The URL to redirect to after logout.
 */
export async function logout(returnUrl: string): Promise<void> {
  try {
    const partnerToken = await fetchPartnerIdToken();

    if (!partnerToken) {
      captureAppMessage('Logout Warning - Missing partner token', {
        context: {
          contextDetails: {
            info: 'No valid federated logout URL returned from the backend.',
          },
        },
        ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
        severityLevel: 'warning',
      });
      return;
    }

    const token = await Auth.getToken();

    const logoutOptions = {
      federated: true,
      accessToken: token,
      returnTo: `${returnUrl}?idToken=${partnerToken}`,
    };
    StandaloneAuth.client.logout(logoutOptions);
  } catch (error: any) {
    captureAppMessage(`Logout Error - ${error.name}`, {
      context: {
        contextDetails: {
          info: `An exception occurred during logout. Error: ${error.message}`,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      severityLevel: 'error',
    });
  }
}
