import { useIntl } from '@leagueplatform/locales';
import { BoxProps } from '@leagueplatform/genesis-commons';
import {
  APP_MANAGED_ROUTES,
  EXTERNAL_LINK,
  EXTERNAL_SSO_LINK,
} from 'common/constants';
import type { Link, LocalesKey } from 'common/types';
import { useGetAppInitDetails } from './use-get-app-init-details';
import { useIsFormsCenterAvailable } from './use-is-forms-center-available.hook';

export type Options = {
  heading?: LocalesKey;
  items: Link[];
};

const boldTextLink: BoxProps = {
  color: 'onSurface.text.subdued',
  fontWeight: 'bold',
};

const boldTextCriticalLink: BoxProps = {
  color: 'onSurface.text.critical',
  fontWeight: 'bold',
};

function useAccountSettingLinks() {
  const { formatMessage } = useIntl();
  const { data: appInitDetails } = useGetAppInitDetails();
  const shouldShowManagePharmacySettingsLink =
    appInitDetails?.productSubType === 'MAPD';

  const accountSettingLinks: Link[] = [
    {
      text: formatMessage({ id: 'STR_PERSONAL_INFO' }),
      to: EXTERNAL_SSO_LINK.PERSONAL_INFO,
      color: '$onSurfaceTextSubdued',
      linkIcon: 'interfaceExternalLink',
      linkProps: boldTextLink,
    },
  ];

  if (shouldShowManagePharmacySettingsLink) {
    accountSettingLinks.push({
      text: formatMessage({ id: 'STR_MANAGE_PHARMACY_SETTINGS' }),
      to: EXTERNAL_SSO_LINK.MANAGE_PHARMACY_SETTINGS,
      color: '$onSurfaceTextSubdued',
      linkIcon: 'interfaceExternalLink',
      linkProps: boldTextLink,
    });
  }

  return accountSettingLinks;
}

function useSupportLinks() {
  const { formatMessage } = useIntl();
  const isFormsCenterAvailable = useIsFormsCenterAvailable();

  const supportLinks: Link[] = [];

  if (isFormsCenterAvailable) {
    supportLinks.push({
      text: formatMessage({ id: 'STR_FORMS_CENTER' }),
      to: APP_MANAGED_ROUTES.FORMS_CENTER_ROUTE,
      linkProps: boldTextLink,
      color: '$onSurfaceTextSubdued',
      linkIcon: 'interfaceChevronRight',
    });
  }

  return supportLinks;
}

export function useUserDropdownConfig(
  handleDeleteAccount: () => void,
): Options[] {
  const { formatMessage } = useIntl();
  const accountSettingLinks = useAccountSettingLinks();
  const supportLinks = useSupportLinks();

  return [
    {
      heading: 'STR_ACCOUNT_SETTINGS',
      items: accountSettingLinks,
    },
    {
      heading: 'STR_SUPPORT',
      items: supportLinks,
    },
    {
      heading: 'STR_PRIVACY_AND_LEGAL_DOCUMENTS',
      items: [
        {
          text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
          to: EXTERNAL_LINK.PRIVACY_POLICY,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_PRIVACY_NOTICES' }),
          to: EXTERNAL_LINK.PRIVACY_NOTICES,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_PRIVACY_FORMS' }),
          to: EXTERNAL_LINK.PRIVACY_FORMS,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_HIPPA_NOTICE_OF_PRIVACY_PRACTICES' }),
          to: EXTERNAL_LINK.HIPPA_NOTICE_OF_PRIVACY_PRACTICES,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_GRAMM_LEACH_BILLEY_ACT_NOTICE' }),
          to: EXTERNAL_LINK.GRAMM_LEACH_BILLEY_ACT_NOTICE,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_NONDISCRIMINATION_NOTICE' }),
          to: EXTERNAL_LINK.NONDISCRIMINATION_NOTICE,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_WASHINGTON_CONSUMER_PRIVACY_NOTICE' }),
          to: EXTERNAL_LINK.WASHINGTON_CONSUMER_PRIVACY_NOTICE,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_APPEALS' }),
          to: EXTERNAL_LINK.APPEALS,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_GRIEVANCES' }),
          to: EXTERNAL_LINK.GRIEVANCES,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
        {
          text: formatMessage({ id: 'STR_DISCLAIMERS' }),
          to: EXTERNAL_LINK.DISCLAIMERS,
          target: '_blank',
          linkIcon: 'interfaceExternalLink',
          color: '$onSurfaceTextSubdued',
        },
      ],
    },
    {
      heading: 'STR_ABOUT',
      items: [
        {
          text: formatMessage({ id: 'STR_ABOUT_CIGNA_HEALTHCARE' }),
          to: APP_MANAGED_ROUTES.ABOUT_CIGNA_ROUTE,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
        {
          text: formatMessage({ id: 'STR_LANGUAGE_ASSISTANCE' }),
          to: EXTERNAL_LINK.LANGUAGE_ASSISTANCE,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
          to: EXTERNAL_LINK.PRIVACY_POLICY,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: formatMessage({ id: 'STR_TERMS_OF_USE' }),
          to: EXTERNAL_LINK.TERMS_OF_USE,
          target: '_blank',
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceExternalLink',
        },
        {
          text: formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' }),
          onClick: handleDeleteAccount,
          linkProps: boldTextLink,
          color: '$onSurfaceTextSubdued',
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
    {
      items: [
        {
          text: formatMessage({ id: 'STR_LOG_OUT' }),
          to: APP_MANAGED_ROUTES.SIGN_OUT,
          color: '$onSurfaceTextCritical',
          linkProps: boldTextCriticalLink,
          linkIcon: 'interfaceChevronRight',
        },
      ],
    },
  ];
}
