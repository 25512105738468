import * as React from 'react';
import { captureAppMessage } from '@leagueplatform/observability';
import { LoadingSpinner } from 'components/loaders/loading-spinner.component';
import { logout } from 'utils/auth/logout';
import { SENTRY_CONSTANTS } from 'common/constants';

export const AuthSignOut: React.FC = () => {
  React.useEffect(() => {
    const initiateLogout = async () => {
      try {
        await logout(window.location.origin);
      } catch (error: any) {
        captureAppMessage(`SignOut Error - ${error.name}`, {
          context: {
            contextDetails: {
              info: `An exception occurred during sign-out. Error: ${error.message}`,
            },
          },
          ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
          severityLevel: 'error',
        });
      }
    };

    initiateLogout();
  }, []);

  return <LoadingSpinner />;
};
