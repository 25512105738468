import {
  StackLayout,
  HeadingText,
  ParagraphText,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { genesisStyled, css } from '@leagueplatform/genesis-commons';
import { HtmlToReact } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

const StyledList = genesisStyled(UtilityText)(
  css({
    listStyle: 'none',
    padding: 0,
    '& > li': {
      position: 'relative',
      marginBottom: '8px',
      fontSize: '14px',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: '8px',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        // backgroundColor: 'black',
      },
    },
  }),
);

const StyledParagraphText = genesisStyled(ParagraphText)(
  css({
    fontSize: '14px',
  }),
);

const StyledStrong = genesisStyled(ParagraphText)(
  css({
    fontSize: '14px',
    fontWeight: '700',
  }),
);

const StyledHeader = genesisStyled(HeadingText)(
  css({
    fontSize: '14px',
    fontWeight: '700',
    marginTop: '24px',
  }),
);

const HtmlOptions = {
  ul: { component: StyledList },
  p: { component: StyledParagraphText },
  strong: { component: StyledStrong },
  h6: { component: StyledHeader },
};

interface ContentSectionProps {
  titleId: string;
  contentId?: string;
}

export const ContentSection = ({ titleId, contentId }: ContentSectionProps) => {
  const { formatMessage } = useIntl();

  return (
    <StackLayout
      css={{
        marginTop: '$half',
        marginBottom: '$one',
      }}
    >
      <HeadingText level="4" size="md" css={{ marginBottom: '$half' }}>
        {formatMessage({ id: titleId as never })}
      </HeadingText>

      {contentId && (
        <HtmlToReact
          htmlString={formatMessage({ id: contentId as never })}
          options={HtmlOptions}
        />
      )}
    </StackLayout>
  );
};
